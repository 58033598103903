.new {
    width: 100%;
    display: flex;
    background-color: rgb(87,168,204);
    height: 780px;
  
    .newContainer {
     margin-top: 120px;
  
      
      .bottom {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 10px;
        margin: 10px;
        display: flex;
        padding: 200px;
        
  
        h1 {
          color: lightgray;
          font-size: 20px;
       
        }
        .left {
          flex: 1;
          text-align: center;
  
          .imagepro {
            width:300px;
            height: 300px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
  
        .right {
          flex: 2;
  
          form {
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            justify-content: space-around;
  
            .formInput {
              width: 40%;
              color: white;
  
              label {
                display: flex;
                align-items: center;
                gap: 10px;
  
                .icon {
                  cursor: pointer;
                  color: rgb(255, 255, 255);
                }
              }
  
              input {
                width: 100%;
                padding: 5px;
                border: none;
                border-bottom: 1px solid gray;
              }
            }
  
            button {
              width: 150px;
              padding: 10px;
              border: none;
              background-color: teal;
              color: white;
              font-weight: bold;
              cursor: pointer;
              margin-top: 10px;
            }
          }
        }
      }
    }
    .error_msg {
      width: 370px;
      padding: 15px;
      margin: 5px 0;
      font-size: 14px;
      background-color: #f34646;
      color: white;
      border-radius: 5px;
      text-align: center;
    }
     .itemImg {
              width: 400px;
              height: 400px;
              border-radius: 50%;
              object-fit: cover;
            }
  }
  