.fullBody{
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.wrap{
		position: absolute;
		top: 44%;
		left: 50%;
	}
}

.spinner-wrap {
	margin: auto;
	position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.spinner {
	
	height: 100px;
	width: 100px;
	display: inline-block;
	margin: -50px auto 0;
	animation: spin 1s steps(12, end) infinite;
	
	&:first-child {
		margin-right: 50px;
	}
	
	i {
		height: 20px;
		width: 7px;
		margin-left: -3px;
		display: block;
		transition: height 0.5s;
		position: absolute;
		left: 50%;
		transform-origin: center 50px;
		background: #6981A6;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
		border-radius: 3px;
	}
	
	&:nth-child(2) i {
		height: 6px;
	}
	&:hover {
		i {
			height: 6px;		
		}
		
		&:nth-child(2) i {
			height: 30px;
		}
	}
}

.spinner i:nth-child(1) { opacity:.08 }
.spinner i:nth-child(2) { transform:rotate(30deg);opacity:.167 }
.spinner i:nth-child(3) { transform:rotate(60deg);opacity:.25 }
.spinner i:nth-child(4) { transform:rotate(90deg);opacity:.33 }
.spinner i:nth-child(5) { transform:rotate(120deg);opacity:.4167 }
.spinner i:nth-child(6) { transform:rotate(150deg);opacity:.5 }
.spinner i:nth-child(7) { transform:rotate(180deg);opacity:.583 }
.spinner i:nth-child(8) { transform:rotate(210deg);opacity:.67 }
.spinner i:nth-child(9) { transform:rotate(240deg);opacity:.75 }
.spinner i:nth-child(10) { transform:rotate(270deg);opacity:.833 }
.spinner i:nth-child(11) { transform:rotate(300deg);opacity:.9167 }
.spinner i:nth-child(12) { transform:rotate(330deg);opacity:1 }

@keyframes spin {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}