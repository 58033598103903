.container {
    width: 85%;
    height: 100%;
    padding: 25px 0;
    /* border: 1px solid red; */
    margin: 20px auto;
    color: white;
    background-color:rgb(3, 24, 33);
}

.container__info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(182, 182, 182, 0.801);
}

.container__info>div {
    width: 105px;
    margin: 0 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 13px;
}

.modalText>p {
    margin: 5px 0;
}

.container__card {
    margin: 10px;
    padding: 5px;
    border-bottom: 1px solid rgba(182, 182, 182, 0.801);
}

.container__card__title {
    width: fit-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.container__card__title>h4 {
    margin: 0;
    margin-bottom: 2px;
}

.container__card__title__icon {
    font-size: 15px;
    margin-right: 8px;
    color: rgb(255, 232, 232);
    cursor: pointer;
    transition: all 0.2s ease;
}

.container__card__info {
    /* width: fit-content; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 0;
}

.container__card__info>div {
    margin-right: 10px;
}

.container__card__info__options {
    display: flex;
    width: 130px;
    justify-content: space-between;
    align-items: center;
}

.container__card__info__options>div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container__card__info__options>div>span {
    margin-left: 5px;
}

.container__card__info__times__container {
    margin-left: 15%;
}

.container__card__info__times__container>div {
    margin-bottom: 10px;
    display: flex;
}

.container__card__info__times__container>div>div {
    /* display: flex; */
    position: relative;
}

.container__card__info__times__container .button {
    background-color: rgb(248,68,100);
    border: 1px solid gray;
    padding: 10px 15px;
    border-radius: 4px;
    margin: 0 5px;
    color: #4abd5d;
    font-weight: 500;
    cursor: pointer;
    font-size: 13px;
    color: white;
}

.price__container {
    display: flex;
    display: none;
    position: absolute;
    top: -290%;
    left: -50%;
    color: white;
    background-color: rgb(234, 229, 229);
    padding: 15px;
    height: 100px;
    width: 100px;
    box-shadow: 0px 0px 1px 1px rgb(56, 1, 1);
    transition: all 0.3s ease;
}

.price__container>div {
    padding: 10px;
    text-align: center;
}

.price__container>div p {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
}

.price__container>div>span {
    font-size: 12px;
    display: inline-block;
    margin: 3px;
}

.container__card__title__icon:hover {
    color: red;
}

.container__card__info__times__container .button:hover .price__container {
    display: flex;
    color: rgb(14, 2, 2);
}


/* HEADER CSS------------------------------- */

.header__container {
   position: relative;
    display: flex;
    height: 300px;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 10px 7.5%;
}

.header_container__info>h1 {
    margin: 0;
    color: white;
    font-size: 35px;
    font-weight: 600;
}

.header_container__info>div {
    display: flex;
    color: white;
    align-items: center;
    padding: 10px 0;
    margin: 5px 0;
}

.header_container__info__grade {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: rgb(219, 219, 219);
    border: 1px solid rgb(219, 219, 219);
    margin-right: 10px;
}

.header_container__info__rating {
    text-align: center;
    margin: 0 10px;
}

.header_container__info__rating>div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_container__info__rating>div>span {
    font-size: 16px;
    font-weight: 700;
}

.header_container__info__rating>span {
    font-size: 11px;
}

.header_container__info__genre {
    display: flex;
    align-items: center;
    margin: 0 10px;
}

.header_container__info__genre>div {
    color: rgb(219, 219, 219);
    padding: 1px 13px;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 10px;
    margin-right: 5px;
    font-size: 12px;
}

.header_container__info__date {
    margin: 0 10px;
    font-size: 12px;
}

.header_container__info__duration {
    display: flex;
    align-items: center;
    margin: 0 10px;
}

.header__container__crew {
    color: rgb(219, 219, 219);
    width: 450px;
}

.header__container__crew>h4 {
    margin-left: 25px;
    color: rgb(219, 219, 219);
}

.header__container__crew__item {
    text-align: center;
}

.header__container__crew__item>img {
    width: 40px;
    border-radius: 50%;
}


/* CALENDAR PART ----------------------------- */

.arrow button {
    /* min-width: 18px;
    min-height: 18px;
    max-width: 45px;
    
    max-height: 28px;
    border-radius: 50%; 

     background-color: #4abd5d; */
}

.dateItem {
    /* margin: 0 10px; */
    /* padding: 10px 25px; */
    text-align: center;
    border-radius: 12px;
    height: 60px;
   
    cursor: pointer;
}

.dateItem>h2 {
    margin: 0;
    font-size: 18px;
}

