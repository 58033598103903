.page {
	display: flex;
	justify-content: center;
	padding: 20px 80px;
	height: 800px;
	background-color: rgb(39,42,44);
	max-width: 100vw;
}
.firstSection {
	width: 70%;
	margin-right: 20px;
}
.secondSection {
	width: 30%;
}

.secondSection > div:nth-child(2) {
	display: flex;
	justify-content: center;
}
.contact > div:first-child,
.cardDetails > div:first-child {
	cursor: text;
	background: #d40027;
	color: #fff;
	font-size: 100%;
	font-weight: 400;

	font-size: 16px;
	padding: 15px 20px;
}
.contact_details {
	display: flex;
	max-width: 100%;
	padding: 20px;
	align-items: center;
	background: white;
}
.contact_details input {
	border: 1px solid #b9b9ba;
	font-size: 14px;
	line-height: 19px;
	font-weight: 400;
	padding: 10px 18px;
	border-radius: 2px;
	color: #333;
	outline: 0;
	max-width: 100%;
	vertical-align: middle;
	resize: none;
	margin-right: 5px;
	margin-top: 10px;
}
.contact_details button {
	cursor: pointer;
	padding: 12px 40px;
	font-size: 16px;
	border-radius: 8px;
	outline: 0;
	border: none;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 2px;
	display: inline-block;
	margin: 12px;
	background: #a5001e;
}

.cardDetails {
	display: flex;
	max-width: 100%;
	padding: 10px 40px;
	background: white;
}
.StoredCard {
	display: flex;
	width: 100%;
	
}
.sidebar {
	width: 35%;
	background-color: #eee;
	font-size: 100%;
	font-weight: 400;
	color: #ebe8e8;
}
.sidebar div {
	position: relative;
	cursor: pointer;
	text-align: left;
	width: 100%;
	font-size: 12px;
	color: #7b7d82;
	clear: both;
	padding: 20px 25px;
	background: #f7f7f7;
	border-bottom: 1px solid #f0f0f0;
}
.cardDetails {
	padding: 20px 25px;
	width: 100%;
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: left;
}
.cardDetails span {
	margin-bottom: 10px;
	font-weight: 600;
}
.sampleCard {
	width: 50%;
	padding: 20px;
	border-radius: 5px;
	-webkit-box-shadow: 0 3px 0 #c9ccd2;
	box-shadow: 0 3px 0 #c9ccd2;
	margin-bottom: 20px;
	position: relative;
	zoom: 1;
	background: #dadee3;
	background: linear-gradient(125deg, #dadee3 0, #dadee3 50%, #d2d6de 51%, #d2d6de 100%);
}
.sampleCard input {
	width: 80%;
	padding: 9px;
	border: 0;
	-webkit-box-shadow: inset 2px 1px 0 #ffffff;
	box-shadow: inset 2px 1px 0 #fcf8f8;
	border-radius: 1px;
	letter-spacing: 3px;
	margin: 10px 0;
}
::placeholder {
	font-size: 12px;
}
.otherDetails {
	display: flex;
	width: 80%;
	justify-content: space-between;
}
.otherDetails input {
	width: 20%;
	padding: 9px;
	border: 0;
	-webkit-box-shadow: inset 2px 1px 0 #bec1c9;
	box-shadow: inset 2px 1px 0 #e8eaf0;
	border-radius: 1px;
	letter-spacing: 3px;
	margin: 10px 0;
}
.payment button {
	background: #f84464;
	border-radius: 8px;
	color: #fff;
	font-weight: 500;
	padding: 13px 40px;
	cursor: pointer;
	text-transform: uppercase;
	letter-spacing: 2px;
	display: inline-block;
	border: none;
	outline: none;
}

/* secondSection----------------------------------- */

.summeryPart {
	width: 100%;
	background: white;
	border: 1px solid #dadada;
	max-height: fit-content;
	margin: auto;
}
.summeryPart > div:first-child {
	padding: 0 30px;
	padding-top: 20px;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 3px;
	margin-bottom: 25px;
	text-align: left;
	color: #c02c39;
}
.summeryPart span {
	padding: 0 30px;
	font-size: 13px;
	font-weight: 400;
	color: #3a404e;
}
.categories {
	padding: 0 30px;
	font-size: 16px;
	color: #1f2533;
	font-weight: 500;
	line-height: 20px;
	display: flex;
	justify-content: space-between;
}
.line {
	/* padding: 0 30px; */
	background: #dadada;
	height: 1px;
	margin: 10px 30px;
}

.charity {
	background: #eeeeee;
	margin: 20px 30px;
	padding: 10px;
	text-align: left;
}
.charity > div:first-child {
	display: flex;
	justify-content: space-between;
}
.charity img {
	width: 100px;
	margin: 0 5px;
}
.text {
	font-size: 12px;
	text-align: center;
}
.total {
	background: #fffcdc;
	padding: 15px 30px;
	display: flex;
	font-size: 100%;
	font-weight: 400;
	justify-content: space-between;
	margin: 20px 0;
}
.ticketType {
	font-size: 14px;
	color: #999;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 25px;
	text-align: center;
}
.cancellation_policy {
	font-size: 100%;
	font-weight: 400;
	vertical-align: baseline;
	padding: 15px 30px;
	font-size: 12px;
	text-align: center;
}
.proceedBtn {
	background: #f84464;
	color: #fff;
	padding: 10px 10px;
	margin: 15px 30px;
	display: flex;
	font-size: 22px;
	font-weight: 400;
	justify-content: space-between;
	border-radius: 10px;
	cursor: pointer;
}
