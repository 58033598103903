.container {
	display: flex;
	padding: 20px 200px;
	background:rgb(87,168,204);
	color: white;
	padding-top: 130px;
	scroll-snap-stop: none;
}

.summeryPart {
	width: 40%;
	background: rgb(39, 42, 44);
	border: 1px solid #dadada;
	color: white;
	max-height: fit-content;
}

.summeryPart>div:first-child {
	padding: 0 30px;
	padding-top: 20px;
	font-weight: 300;
	color: white;
	text-transform: uppercase;
	letter-spacing: 3px;
	margin-bottom: 25px;
	text-align: left;
	color: #c02c39;
}

.summeryPart span {
	padding: 0 30px;
	font-size: 13px;
	color: white;
	font-weight: 400;
	color: #ececec;
}

.categories {
	padding: 0 30px;
	font-size: 16px;
	color: #0d49d5;
	font-weight: 500;
	color: white;
	line-height: 20px;
	display: flex;
	justify-content: space-between;
}

.line {
	/* padding: 0 30px; */
	background: #ece5e5;
	height: 1px;
	margin: 10px 30px;
}

.charity {
	background: #0b0101;
	margin: 20px 30px;
	padding: 10px;
	text-align: left;
}

.charity>div:first-child {
	display: flex;
	justify-content: space-between;
}

.charity img {
	width: 100px;
	margin: 0 5px;
}

.text {
	font-size: 12px;
	text-align: center;
}

.total {
	background:  #fdfef9;
	padding: 15px 30px;
	color: #0e0000;
	display: flex;
	font-size: 100%;
	font-weight: 400;
	justify-content: space-between;
	margin: 20px 0;
}

.ticketType {
	font-size: 14px;
	color: #d80707;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 25px;
	text-align: center;
}

.cancellation_policy {
	font-size: 100%;
	font-weight: 400;
	vertical-align: baseline;
	padding: 15px 30px;
	font-size: 12px;
	text-align: center;
}

.proceedBtn {
	background: #b50021;
	color: #fff;
	padding: 10px 20px;
	margin: 15px 30px;
	display: flex;
	font-size: 18px;
	font-weight: 400;
	justify-content: space-between;
	border-radius: 10px;
	cursor: pointer;
}