
.card-container {
  display: flex;
  flex-wrap: wrap;
}

.movie-card {
  flex-basis: 300px;
  background-size: cover;

  background-repeat: no-repeat;
  height: 500px;
  width: 300px;
  margin: 10px;
  position: relative;
  border-radius: 5%;
  padding-top: 7%;
  display: block;

}
  
  
.namemove{

  text-align: center;
  color: rgb(234, 232, 240) ;
  top: 60%;
  margin-top: 300px;
  font-weight: bold;
}
