@use "../../scss/" as *;

.banner {
   
    height: 300px;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include overlay();
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(to top, $body-bg, rgba($black, 0));
    }
}
